<app-progress-spinner
    class="main-app-spinner"
    [class.mobile]="(locationFacade.getIsMobile() | async) === true"
    *ngIf="currentView !== views.LIST && loading"
>
</app-progress-spinner>

<app-rotate-message *ngIf="showRotationMessage"></app-rotate-message>

<!-- To prevent bugs, we must be careful with how we conditionally render the leaflet map and it's parent containers -->
<!-- We use style.visibility on the parent container, because if we were to use fxShow or display=none, the map      -->
<!-- instance cannot correctly calculate its dimensions after an orientation change.                                 -->
<!-- If we were to use use ngIf, the map would be completely removed from the dom, which will cause leaflet errors   -->

<div class="mobile-layout-container" [style.visible]="showRotationMessage ? 'hidden' : 'visible'" fxFill fxLayout="column">
  <div fxLayout="column" class="mobile-header-container">
     <div class="map-header-mobile" > 
      <div class="filter-icon-container"  (click)="handleOpenFilterDialog.emit()"> 
        <span class="material-icons">filter_list</span>
    </div>
    </div>
  </div>
  <app-mobile-container
    [isMapShown]="!showRotationMessage" 
    [text]="text"
    (toggleMobileViewContext)="toggleMobileViewContext()"
  >  
    <app-asset-list 
      *ngIf="currentView===views.LIST && !showRotationMessage"
      (listUpdated)="handleAssetListUpdate()"
      class="content-pane">
    </app-asset-list>
  </app-mobile-container>
</div>